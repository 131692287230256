import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import ReactMarkdown from "react-markdown"
import rehypeRaw from 'rehype-raw'


const PrivacyPolicyPetneck2 = ({data: {strapiPrivacyApp}}) => (
  <Layout>
   
    <SEO title="PETNECK2 About the Programme"/>

      <div class="content">
        <p><ReactMarkdown rehypePlugins={[rehypeRaw]}>{strapiPrivacyApp.Text}</ReactMarkdown></p>
      </div>
    

  </Layout>
)

export const aboutProgrammeQuesry = graphql`
  query PrivacyAppQuery {
    strapiPrivacyApp {
     Text
    }
  }
`

export default PrivacyPolicyPetneck2
